import React from 'react'
import '../css/Animation.css';


const Animation = () => {
    return (
        <div>
        <p className = "animated-text text-9xl">&#x0355; &#x0355; &#x0355; &#x0355; &#x0355; &#x0355; &#x0355;</p>
        </div>
    )
}

export default Animation
